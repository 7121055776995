import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, getDayWeek, getDayWeekV2, secondsToHm, secondsToHours } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import { api } from '../../../_helpers/api';
import Widget8Skeleton from './Widget8Skeleton';
import ReactApexChart from 'react-apexcharts';
import { Hourglass, Timer } from 'phosphor-react';
import moment from 'moment';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function Widget8({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);

  const [chartWorkedTime, setChartWorkedTime] = useState({
    stats: null,
    options: {
      colors: [
        "#FF4560",
        "#26E7A6"
      ],
      chart: {
        id: 'horizontal-bar-chart',
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          distributed: true
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#4F7091'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#4F7091'
          },
          formatter: (value) => {
            return secondsToHm(value) ;
          }
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            }
          },
        },
      ],
      dataLabels: {
        enabled: false, // Habilita os rótulos de dados
        formatter: (value) => {
          return secondsToHm(value); // Define o rótulo personalizado aqui
        },
      },
      legend: {
        show: false
      }
    },
    series: [
      {
        name: '',
        data: []
      }
    ]
  })

  const getData = async ({filterStartDate, filterEndDate, filterTeams, filterTeammates}) => {
    try {
      setFetching(true)

      const response = await api.get(`/stats/executive/daily_avg?current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget8!!!')
      }
      const { data } = response

      if (data && data.data.length > 0) {
        const seriesTotalHourWorkAvg = data.data.map((item) => Math.round(item.total_hour_work_avg))
        const maxTotalWorkAvg = parseInt(Math.max(...seriesTotalHourWorkAvg))
        setChartWorkedTime({
          stats: {
            avg_worked_time: secondsToHm(data.avg_worked_time),
            hours_expected_work: data.hours_expected_work
          },
          options: {
            ...chartWorkedTime.options,
            yaxis: {
              ...chartWorkedTime.options.yaxis,
              max: maxTotalWorkAvg > parseInt(data.hours_expected_work_avg) ? maxTotalWorkAvg : parseInt(data.hours_expected_work_avg),
              tickAmount: 4,
              min: 0
            },
            annotations: {
              yaxis: [{
                y: parseInt(data.hours_expected_work_avg),
                borderColor: '#FDBE2B',
                label: {
                  borderColor: '#FDBE2B',
                  style: {
                    color: '#fff',
                    background: '#FDBE2B',
                  },
                  text: secondsToHm(data.hours_expected_work_avg),
                }
              }]
            },
            colors: data.data.map((item) => {
              if (moment(item.day_ref).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                return '#187DFF'
              }
              return item.reach_work_target ? '#22BEAD' : '#FF7A90'
            }),
            xaxis: {
              ...chartWorkedTime.options.xaxis,
              categories: data.data.map((item) => {
                if (moment(item.day_ref).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                  return t('widgets.widget8.today')
                }
                return getDayWeekV2(moment(item.day_ref).day(), true, t)
              })
            }
          },
          series: [
            {
              name: '',
              data: data.data.map((item) => Math.round(item.total_hour_work_avg)),
            }
          ]
        })

      }
      setFetching(false)

    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData(filters)
  }, [])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <Link className={"linkDashRedirect"} to={'/productive/reports/timegoal'}>
                  <h6 className={style.title}>
                    {t("widgets.widget8.worked_time_title")}
                  </h6>
                </Link>
                <BefectiveTooltip
                  title={
                    <Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>
                      {t("widgets.widget8.worked_time_tooltip")}
                    </Typography>}
                  placement="right-start"
                  arrow
                  interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
              {/* {
                <button className={style.btnToggle} onClick={() => setToggleView(!toggleView)}>
                  {
                    toggleView
                      ?
                      <Hourglass size={18} weight="bold" color='#98ACC1' />
                      :
                      <Timer size={18} weight="bold" color='#98ACC1' />
                  }
                </button>
              } */}
            </div>
            <h3 className={style.timeTotal}>{chartWorkedTime.stats.avg_worked_time || '0'}</h3>
            <div className={style.chartContainer}>
              <ReactApexChart options={chartWorkedTime.options} series={chartWorkedTime.series} type="bar" height={200} />
            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget8Skeleton />
      </Grid>

  )
}
