import React, { useEffect, useState } from 'react'
import styles from './StatsInfoHeader.module.scss'
import { Box, Button, Popover, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import { Robot } from 'phosphor-react';
import PopoverConfirm from '../teams_settings/PopoverConfirmDelete';

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: '42px',
    backgroundColor: '#FDBE2B',
    borderRadius: '2px',
    cursor: 'pointer',
    fontWeight: '600',
    border: 'none',
    color: '#106276',
    textTransform: 'none',
    boxShadow: 'none',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: '#FDBE2B',
      boxShadow: '0px 0px 4px 0px #2B2B2B55',
    },
    '& > span': {
      fontSize: '13px',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  },
  customLabel: {
    fontSize: '10px'
  }

}));

export default function StatsInfoHeader({
  statsGlobal,
  selectedRows,
  getData,
  alertActions,
  activeFilter,
  setActiveFilter,
  setSelectedRows,
  usersWithoutAgentsFilter,
  setUsersWithoutAgentsFilter
}) {
  const { t } = useTranslation('common');

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);
  const [disableActions, setDisableActions] = useState(false);
  const [openModalMoveTo, setOpenModalMoveTo] = useState(null);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [anchorElInvitationUsers, setAnchorElInvitationUsers] = useState(null);
  const openConfirmInvitationUsers = Boolean(anchorElInvitationUsers);
  const idConfirmInvitationUsers = openConfirmInvitationUsers ? 'simple-popover-invitation-users' : undefined;

  const handleClickInvitationUsers = (event) => {
    setAnchorElInvitationUsers(event.currentTarget);
  };

  const handleCloseInvitationUsers = () => {
    setAnchorElInvitationUsers(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleCloseInvitationUsers()
  };

  const open = Boolean(anchorEl);
  const id = open ? 'actions-popover' : undefined;

  const handleConfirmInvitationUsers = async () => {

    try {

      if (selectedRows.length === 0) {
        throw new Error('Sem usuarios selecionados')
      }
      setDisableActions(true)

      const payload = {
        users: selectedRows.join(',')
      }
      
      const res = await api.post(`/users/v2/send-email-invitation`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao convidar os colaboradores')
      }

      await getData()
      handleClose()
      alertActions.success(t('settings.users.success.send_invitation_users'))
    } catch (error) {
      console.error(error)
      alertActions.error(t('settings.users.error.send_invitation_users'))
    } finally {
      setDisableActions(false)
    }
  }

  const removeTeam = (teamId) => {
    setTeamsSelected(teamsSelected.filter((t) => t.id !== teamId))
    setSelectedRows(selectedRows.filter(r => r !== teamId))
  }

  return (
    <header className={styles.headerCompany}>
      <div className={styles.containerInfoCompany}>
        <div className={styles.boxStatsCompany}>
          <h6 className={styles.titleCompany}>{t('settings.users.title_table')}</h6>
          <div className={styles.boxStatsColabs}>
            <div className={`${styles.stats} ${activeFilter === 0 && styles.activeFilter}`} onClick={() => setActiveFilter(0)}>
              <div className={`${styles.circle} ${styles.circleBlue}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.users_register')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal?.total_users || 0}`}</div>
            </div>
            <div className={`${styles.stats} ${activeFilter === 1 && styles.activeFilter}`} onClick={() => setActiveFilter(1)}>
              <div className={`${styles.circle} ${styles.circleGreen}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.licenses_in_use')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal?.total_active_users || 0}`}</div>
            </div>
            <div className={`${styles.stats} ${activeFilter === 2 && styles.activeFilter}`} onClick={() => setActiveFilter(2)}>
              <div className={`${styles.circle} ${styles.circleRed}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.users_inactive')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal?.total_unactive_users || 0}`}</div>
            </div>
            <div className={`${styles.statsNoHover}`}>
              <div className={`${styles.circle} ${styles.circleYellow}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.licenses_available')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal?.remaining_users || 0} / ${statsGlobal?.allowed_users || 0}`}</div>
            </div>
            <div className={`${styles.stats} ${usersWithoutAgentsFilter === true && styles.activeFilter}`} onClick={() => setUsersWithoutAgentsFilter(curState => !curState)}>
              <Robot color='#FC6662' size={16} weight='bold' />
              <div className={styles.labelStats}>{`${t('settings.users.users_without_agent')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal?.total_users_without_agent}`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.boxActions}>
        <Button disableElevation aria-describedby={id} variant="contained" color="primary" onClick={handleClick} endIcon={<ExpandMore />} className={classes.button} disabled={!(selectedRows.length > 0)}>
          {`${t('settings.teams.actions')}`}
        </Button>
        {selectedRows.length > 0 && <span className={styles.selecteds}>{`( ${selectedRows.length} ${t('settings.users.selecteds')} )`}</span>}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#1E6B7F',
              marginTop: '10px'
            }
          }}
        >
          <Box className={styles.menuOptionsActions}>
            <button className={disableActions ? styles.menuOptionsActionButtonDisabled : styles.menuOptionsActionButton} disabled={disableActions} aria-describedby={idConfirmInvitationUsers} onClick={handleClickInvitationUsers}>{t('settings.users.send_email_invitation')}</button>
          </Box>
        </Popover>
      </div>
       {/* Start Confirm Dialog Invitation Users */}
        <PopoverConfirm
          idConfirm={idConfirmInvitationUsers}
          openConfirm={openConfirmInvitationUsers}
          anchorEl={anchorElInvitationUsers}
          handleClose={handleCloseInvitationUsers}
          handleConfirm={handleConfirmInvitationUsers}
          title={t('settings.users.send_email_invitation_msg_confirm')}
          maxWidth='auto'
        />
      {/* End Confirm Dialog Invitation Users */}
    </header>
  )
}
