import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core'
import style from './styles.module.scss';
import Paper from '@material-ui/core/Paper';
import { ArrowClockwise, ArrowSquareDown, ArrowsDownUp, Briefcase, CalendarBlank, CalendarCheck, CalendarX, CheckSquare, Clock, ListChecks, Monitor, PlusMinus, SlidersHorizontal, Smiley, Sun, Users, UsersThree } from 'phosphor-react';
import Toolbar from '../../_components/_shared/Toolbar';
import Filterbar from '../../_components/_shared/Filterbar';
import { useTranslation } from 'react-i18next';
import { history } from '../../_helpers';
import { connect } from 'react-redux';
import { usersActions } from '../../_actions';
import { bindActionCreators } from 'redux';

function ProductiveReportsPage({ userDetails, usersActions }) {
  const { t, i18n } = useTranslation('common');
  const [loading, setLoading] = useState(true)

  const getUserData = async () => {
    setLoading(true)
    await usersActions.refresh(userDetails);
    setLoading(false)
  }

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.reports');
    getUserData()
  }, [])

  return (
    <section className="mainContent productivity">
      <Toolbar />
      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhenYear={'disabled'}
        showShareBtn={false}
      />
      <Box className={style.containerProductiveReportsPage}>
        <Paper className={style.paperContainer} elevation={0}>
          <Typography variant="h6" className={style.title}>{t('productive_reports.title_page_reports')}</Typography>
          {
            !loading
              ?
              <Grid container spacing={1}>
                
                {
                  (!userDetails.allowed_reports || userDetails.allowed_reports.length === 0) && (
                    <Grid item xs={12}>
                      <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.none_reports')}</Typography>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('advanced') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/advanced')}>
                        <CardContent className={style.cardReportContent}>
                          <SlidersHorizontal size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.advanced')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_advanced')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('realtime') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/realtime')}>
                        <CardContent className={style.cardReportContent}>
                          <CalendarBlank size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.realtime')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_realtime')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('time_goal') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/timegoal')}>
                        <CardContent className={style.cardReportContent}>
                          <Clock size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.working_day')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_working_day')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('work_goal') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/workgoal')}>
                        <CardContent className={style.cardReportContent}>
                          <Briefcase size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.performance')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_performance')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('mood') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/mood')}>
                        <CardContent className={style.cardReportContent} >
                          <Smiley size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.mood')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_mood')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('teams') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/teams')}>
                        <CardContent className={style.cardReportContent}>
                          <UsersThree size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.teams')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_teams')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('teammates') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/teammates')}>
                        <CardContent className={style.cardReportContent}>
                          <Users size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.teammates')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_teammates')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('inactivity') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/inactivity')}>
                        <CardContent className={style.cardReportContent}>
                          <ArrowSquareDown size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.inactivity')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_inactivity')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('periodic_reports') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/periodic')}>
                        <CardContent className={style.cardReportContent}>
                          <ArrowClockwise size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.periodic_reports')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_periodic_reports')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('vacation') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/vacation')}>
                        <CardContent className={style.cardReportContent}>
                          <Sun size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.vacation')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_vacation')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('absenteeism') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/absenteeism')}>
                        <CardContent className={style.cardReportContent}>
                          <CalendarX size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.absenteeism')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_absenteeism')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('potential_productive') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/potential-productive')}>
                        <CardContent className={style.cardReportContent}>
                          <CalendarCheck size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.potential_productive')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_potential_productive')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('potential_productive') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/fatigue')}>
                        <CardContent className={style.cardReportContent}>
                          <PlusMinus size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.fatigue')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_fatigue')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  userDetails.allowed_reports.includes('checklists') && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/checklists')}>
                        <CardContent className={style.cardReportContent}>
                          <CheckSquare size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.checklists')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_checklists')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

                {
                  (userDetails.profile_key === 'admin' || userDetails.allowed_reports.includes('transactional')) && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/transactional')}>
                        <CardContent className={style.cardReportContent}>
                          <ArrowsDownUp size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.transactional')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_transactional')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }
                
                {
                  (userDetails.profile_key === 'admin' || userDetails.allowed_reports.includes('collection_monitoring')) && (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/productive/reports/collection_monitoring')}>
                        <CardContent className={style.cardReportContent}>
                          <Monitor size={24} color="#22BEAD" weight="bold" />
                          <Typography variant="h6" className={style.cardReportTitle}>{t('productive_reports.card_reports.collection_monitoring')}</Typography>
                          <Typography variant="p" className={style.cardReportSubtitle}>{t('productive_reports.card_reports.subtitle_collection_monitoring')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }

              </Grid>
              :
              <Grid container spacing={1}>
                <CircularProgress />
              </Grid>
          }

        </Paper>
      </Box>
    </section>
  )
}

function mapDispatchToProps(dispatch) {

  return {
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsPage)
