import React, { useState } from 'react'
import { Box, Button, CircularProgress, Popover, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  customLabel: {
    fontSize: '10px'
  }
}));

export default function PopoverConfirm({
  idConfirm,
  openConfirm,
  anchorEl,
  handleClose,
  handleConfirm,
  title='',
  maxWidth='350px'
}) {
  
  const { t } = useTranslation('common');
  const classes = useStyles()

  const [disableButton, setDisableButton] = useState(false);

  const handleActionConfirm = async () => {
    setDisableButton(true)
    await handleConfirm()
    setDisableButton(false)
  }
  return (
    <Popover
    id={idConfirm}
    open={openConfirm}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    disableEnforceFocus
    elevation={0}
  >
    <Box
      sx={{
        backgroundColor: '#FFE0DF'
      }}
    >
      <Typography variant="subtitle2" style={{ padding: '12px', color: '#FC6662', fontSize: '12px', fontWeight: '600', maxWidth: maxWidth }}>{title}</Typography>
      <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
        <Button
          onClick={handleClose}
          disabled={disableButton}
          style={{
            width: '42px !important',
            height: '25px',
            backgroundColor: !disableButton ? '#fff' : '#F5F6F9',
            color: !disableButton ? '#FC6662' : '#98ACC1',
            textTransform: 'capitalize'
          }}
          classes={{ label: classes.customLabel }}
        >
          {t('settings.teams.no')}
        </Button>
        <Button
          onClick={handleActionConfirm}
          disabled={disableButton}
          style={{
            width: '42px !important',
            height: '25px',
            backgroundColor: !disableButton ? '#FC6662' : '#F5F6F9',
            color: !disableButton ? '#fff' : '#98ACC1',
            textTransform: 'capitalize'
          }}
          classes={{ label: classes.customLabel }}
        >
          {
            !disableButton
            ?
            t('settings.teams.yes')
            :
            <CircularProgress size={'10px'} style={{ color: '#98ACC1' }}/>
          }
        </Button>
      </Box>
    </Box>
  </Popover>
  )
}
