import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import TimeGoalFull from '../_components/reports/timegoal/TimeGoalFull'
import TimeGoalFullSkeleton from '../_components/reports/timegoal/TimeGoalFullSkeleton'
import Moment from 'moment';
import {useTranslation, initReactI18next} from "react-i18next";

import {goalsActions, shareActions, statsActions} from '../_actions';
import ShareModal from '../_components/_shared/ShareModal';

function TimeGoalPage(props) {
    const {t, i18n} = useTranslation('common');

    const {goalsActions, shareActions, statsActions} = props;

    let loading = props.loading;
    let filter = props.filter || {};


    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);

    function closeShareModal() {
        shareActions.closeModal()
    }

    useEffect(() => {
        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        let teams = null;
        let teammates = null;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }

        goalsActions.getGoals(startDate, endDate, teams, teammates);
        statsActions.getStats(startDate, endDate, teams, teammates);


    }, [filter])


    useEffect(() => {
        document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('standards.working_day');
    });


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <Filterbar/>
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            !minimumTimeElapsed || loading
                                ? <TimeGoalFullSkeleton/>
                                : <TimeGoalFull/>
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>

            <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
                closeShareModal()
            }}></div>
            <section className={'modal' + (props.share.modal ? ' open' : '')}>
                <ShareModal reports='activeTimeGoal' filters={filter}/>
            </section>
        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        goalsActions: bindActionCreators(goalsActions, dispatch),
        statsActions: bindActionCreators(statsActions, dispatch),
        shareActions: bindActionCreators(shareActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        loading: state.goals.loading,
        filter: state.filter,
        share: state.share
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TimeGoalPage)
