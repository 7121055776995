import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import "react-datepicker/dist/react-datepicker.css";
import { alertActions, filterActions, teamsActions } from '../../_actions';
import { statsActions } from '../../_actions';
import { teammatesActions } from '../../_actions';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../_helpers/useOutsideClick';
import IconSearch from '../_icons/IconSearch';
import IconClear from '../_icons/IconClear';
import styles from './FilterbarUsersSettings.module.scss'
import { authHeader, history } from '../../_helpers';
import IconImportExport from '../_icons/IconImportExport';
import { ModalImportUsers } from '../users_settings/ModalImportUsers';
import WhoSingleTeam from './filters/WhoSingleTeam';
import ProfilesFilter from './filters/ProfilesFilter';
import { api } from '../../_helpers/api';
import WhoMultiSkeleton from './filters/WhoMultiSkeleton';

const useStyles = makeStyles((theme) => ({
	button: {
		minWidth: '100px',
		minHeight: '42px',
		backgroundColor: '#FDBE2B',
		borderRadius: '2px',
		cursor: 'pointer',
		fontWeight: '600',
		border: 'none',
		color: '#106276',
		textTransform: 'none',
		boxShadow: 'none',
		transition: '0.2s',

		'&:hover': {
			backgroundColor: '#FDBE2B',
			boxShadow: '0px 0px 4px 0px #2B2B2B55',
		},
		'& > span': {
			fontSize: '13px',
			display: 'flex',
			flexWrap: 'nowrap'
		}
	}

}));

function FilterbarUsersSettings(props) {

	const classes = useStyles()
	const refFilter = useRef();
	const searchInput = useRef(null);

	const { t, i18n } = useTranslation('common');
	const { alertActions, filterActions, getData, filterLabel, hasCreateUser, userDetails, enableImport } = props;
	const [filterWhatValue, setFilterWhatValue] = useState('');
	const [filterWhat, setWhat] = useState(true);

	const [anchorEl, setAnchorEl] = useState(null);
	const [disableActions, setDisableActions] = useState(false);
	const [fetchingProfiles, setFetchingProfiles] = useState(false)

	const open = Boolean(anchorEl);
	const id = open ? 'export-popover' : undefined;

	
	const [openModalImportUsersStruture, setOpenModalImportUsersStruture] = useState(false)
	const [openModalDeactiveUser, setOpenModalDeactiveUser] = useState(false)

	const [profilesOptions, setProfilesOptions] = useState([])

	const getProfilesOptions = async () => {
		try {
			setFetchingProfiles(true);
			const {data} = await api.get('/settings/access-profile-options', { headers: authHeader() })
			setProfilesOptions(data || []);
		} catch (error) {
			console.log(error);
		}finally{
			setFetchingProfiles(false);
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenModalImportUsersStructure = () => {
		setOpenModalImportUsersStruture(true);
		handleClose()
	};
	

	const handleOpenModalDeactiveUser = () => {
		setOpenModalDeactiveUser(true);
		handleClose()
	};

	const handleChange = e => {
		setFilterWhatValue(e.target.value)
	}

	const changeFilterWhat = () => {

		let value = filterWhatValue.toLowerCase();

		if (value.length > 2) {
			filterActions.set(value, "what");
		} else {
			filterActions.set('', 'what');
		}

	}

	function submitWhat(e) {
		if (e.keyCode == 13) {
			changeFilterWhat()
			let value = filterWhatValue.toLowerCase();
			getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, search_text: value, profiles: props.globalFilter.profiles, teams: props.globalFilter.teams })
		}
	}

	function clearWhat() {
		setFilterWhatValue('');
		filterActions.clear("what");
		filterActions.set('', 'what');
		getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, search_text: '', profiles: props.globalFilter.profiles, teams: props.globalFilter.teams })
	}

	useEffect(() => {
		getProfilesOptions()
	}, [])

	return (
		<div className={'mainFilterbar'}>
			<div className={'filters'}>

				<WhoSingleTeam openWho={props.openWho} />

				{
					fetchingProfiles
					?
					<WhoMultiSkeleton />
					:
					<ProfilesFilter profiles={profilesOptions} getData={props.getFilterProfiles} globalFilter={props.globalFilter}/>
				}

				<div className={filterWhat ? `filter what open` : `what filter`} ref={refFilter}>
					<div className={'holder'}>
						<span className={'label'}>{t('filter.what')}:</span>
						<span className={'value'} onClick={() => {
							setWhat(!filterWhat);
						}}>{filterWhatValue}

							{!filterWhatValue && (filterLabel || 'Search')}
						</span>

						<input ref={searchInput} className={'input'} placeholder={t('filter.search_2')} value={filterWhatValue}
							onChange={handleChange} onKeyUp={(e) => {
								submitWhat(e);
							}} />
						<div className={'clear'} onClick={() => {
							clearWhat()
						}}><IconClear /></div>
						<div className={filterWhat ? `clear submit show` : ` clear submit`} onClick={() => {
							changeFilterWhat()
						}}><IconSearch /></div>
					</div>
				</div>
				
			</div>
			<div className={styles.buttonActionsGroup}>
				{
					(['magic', 'admin'].includes(userDetails.profile_key) && enableImport) && (
						<button className={styles.btnImport} style={{ marginRight: '4px' }} onClick={handleClick} aria-describedby={id}>
							<IconImportExport />
						</button>
					)
				}

				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					PaperProps={{
						style: {
							backgroundColor: '#1E6B7F',
							marginTop: '10px'
						}
					}}
				>
					<Box className={styles.menuOptionsActions}>
						<button className={disableActions ? styles.menuOptionsActionButtonDisabled : styles.menuOptionsActionButton} disabled={disableActions} onClick={handleOpenModalImportUsersStructure}>{t('settings.users.import_users')}</button>
						<button className={disableActions ? styles.menuOptionsActionButtonDisabled : styles.menuOptionsActionButton} disabled={disableActions} onClick={handleOpenModalDeactiveUser}>{t('settings.users.deactive_users')}</button>
					</Box>
				</Popover>

				{
					(hasCreateUser && userDetails.allowed_create) &&
						<Button variant="contained" color="secondary" className={classes.button} onClick={() => history.push('/global-settings/users/create')}>
							{`+ ${t('settings.users.user')}`}
						</Button>
				}
			</div>

			<ModalImportUsers
				openModalImportUsers={openModalImportUsersStruture}
				setOpenModalImportUsers={setOpenModalImportUsersStruture}
				setAnchorEl={setAnchorEl}
				alertActions={alertActions}
				getUsersData={() => {
					getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, search_text: '' })
				}}
				title={t("settings.users.modal_import_users.title")}
				info1={t("settings.users.modal_import_users.info_1")}
				info2={t("settings.users.modal_import_users.info_2")}
				info_placeholder={t("settings.users.modal_import_users.placeholder_file")}
				exemple_url={"https://befective-staging.s3.amazonaws.com/plan_model_import_users.xlsx"}
				end_point={`/users/import-user-full`}
				success_msg={t("settings.users.modal_import_users.success_import_users")}
				error_msg={t("settings.users.modal_import_users.error_import_users")}
			/>

			<ModalImportUsers
				openModalImportUsers={openModalDeactiveUser}
				setOpenModalImportUsers={setOpenModalDeactiveUser}
				setAnchorEl={setAnchorEl}
				alertActions={alertActions}
				getUsersData={() => {
					getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, search_text: '' })
				}}
				title={t("settings.users.modal_deactive_users.title")}
				info1={t("settings.users.modal_deactive_users.info_1")}
				info2={t("settings.users.modal_deactive_users.info_2")}
				info_placeholder={t("settings.users.modal_deactive_users.placeholder_file")}
				exemple_url={"https://befective-staging.s3.us-east-1.amazonaws.com/user_mass_deactive_exemple.xlsx"}
				end_point={`/users/v2/mass-deactive`}
				success_msg={t("settings.users.modal_deactive_users.success_import_users")}
				error_msg={t("settings.users.modal_deactive_users.error_import_users")}
				bnt_agree={t("settings.users.modal_deactive_users.import")}
			/>
		</div>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
		teammatesActions: bindActionCreators(teammatesActions, dispatch),
		teamsActions: bindActionCreators(teamsActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		teammates: state.teammates.payload,
		loadingTeammates: state.teammates.loading,
		teams: state.teams.payload,
		loadingTeams: state.teams.loading,
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(FilterbarUsersSettings)
