import React, { useRef, useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "react-datepicker/dist/react-datepicker.css";

import { filterActions } from "../../../_actions";
import { statsActions } from "../../../_actions";
import { teammatesActions } from "../../../_actions";

import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from "../../../_helpers/useOutsideClick";

import IconCheck from "../../_icons/IconCheck";
import IconClear from "../../_icons/IconClear";
import IconTeam from "../../_icons/IconTeam";

function ProfilesFilter({ profiles = [], getData = async () => {}, globalFilter = null, ...props }) {
  const { t } = useTranslation("common");
  const refFilter = useRef();
  const searchInput = useRef(null);
  const [valueFilter, setValueFilter] = useState("");
	const [profilesSelected, setProfilesSelected] = useState([]);
  const [filterProfile, setFilterProfile] = useState(false);
  const [profilesList, setProfilesList] = useState(profiles);
	const [totalFiltered, setTotalFiltered] = useState(0);

  const changeFilterProfile = (event) => {
    const { value } = event.target;

    setValueFilter(value);

    if (value.length > 2) {
      const assessmentFiltered = profiles.filter((assessment) =>
        assessment.label.toLowerCase().includes(value.toLowerCase())
      );
      setProfilesList(assessmentFiltered);
    } else {
      setProfilesList(profiles);
    }
  };

  useOutsideClick(refFilter, (target) => {
    if (filterProfile) {
      if (
        refFilter &&
        !target.includes("react-datepicker") &&
        !target.includes("column") &&
        !target.includes("who") &&
        !target.includes("input")
      ) {
        if (filterProfile) {
          setFilterProfile(false);
        }
      }
    }
  });

  useEffect(() => {
    searchInput.current.focus();
  }, [filterProfile]);

  const onSelectProfile = async (profile) => {
    // setFilterProfile(false);
		const profilesFiltered = addAndRemoveProfile(profile);
    await getData({ isLoading: true, page: globalFilter.page, pageSize: globalFilter.pageSize, profiles: (profilesFiltered && profilesFiltered.length > 0) ? profilesFiltered : null });
  };


	const addAndRemoveProfile = (profile) => {
		if(profilesSelected.find(p => p.value == profile.value)) {
			const filtered = profilesSelected.filter(p => p.value != profile.value);
			setProfilesSelected(filtered);
			setTotalFiltered(totalFiltered - 1);
			const profileItem = document.getElementById(`profile-${profile.value}`);
			profileItem.classList.remove("active");
			return filtered
		} else {
			const filtered = [...profilesSelected, profile];
			setProfilesSelected(filtered);
			setTotalFiltered(totalFiltered + 1);
			const profileItem = document.getElementById(`profile-${profile.value}`);
			profileItem.classList.add("active");
			return filtered
		}
	}
	
	async function clearWho() {
		setTotalFiltered(0);
    setProfilesSelected([]);

		let profiles = document.querySelectorAll(".profileList");

		[].forEach.call(profiles, function (el) {
				el.classList.remove("active");
		});

		
    await getData({ isLoading: true, page: globalFilter.page, pageSize: globalFilter.pageSize, profiles: null });
	}


  return (
    <div
      className={filterProfile ? "filter who open" : "who filter"}
      ref={refFilter}
    >
      <div className="holder">
        <span className="label">{t("filter.profiles")}:</span>
        <span
          className="value"
          onClick={() => {
            setFilterProfile(!filterProfile);
          }}
        >
          {totalFiltered > 0 && (
						<div className="helper teams">
								<div className="icon"><IconTeam /></div>
								{totalFiltered}
						</div>
					)}
					{(totalFiltered == 0) && t('filter.select')}
        </span>
        <input
          className="input"
          ref={searchInput}
          placeholder={t("filter.search")}
          value={valueFilter}
          onChange={changeFilterProfile}
        />
        <div
          className="clear"
          onClick={() => {
            clearWho();
          }}
        >
          <IconClear />
        </div>
      </div>
      <div className={"comp single"}>
        <div className="column">
          <div className="title">{t("filter.profiles")}</div>
          <ul>
            {profilesList && profilesList.length > 0 ? (
              profilesList.map((profile) => (
                <li
                  id={`profile-${profile.value}`}
                  className={`profileList`}
                  key={profile.value}
                  onClick={() => onSelectProfile(profile)}
                >
                  <div className="check">
                    <IconCheck />
                  </div>
									{profile.is_default_profile === true 
										? 
										<div className="label">{t(`settings.teams.profiles.${profile.key}`)}</div>
									  :
										<div className="label">{profile.label}</div>
									}
                </li>
              ))
            ) : (
              <span className="noDataFilter">{t("filter.no_profiles")}</span>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    statsActions: bindActionCreators(statsActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    teammates: state.teammates.payload,
    filter: state.filter,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesFilter);
