import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box } from '@mui/material';
import './TeamsCollapsibleTable.scss';
import { useTranslation } from 'react-i18next';
import { getAvatarUrl, secondsToHm } from '../../../_helpers';
import { Avatar, TablePagination, Tooltip, withStyles } from '@material-ui/core';
import Pagination from '../../reports/collection_monitoring/Pagination';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: '300px',
  },
}))(Tooltip);

const CustomColoredTooltip = ({ backgroundColor, children, ...props }) => {
  const ColoredTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: backgroundColor,
      maxWidth: '300px',
    },
  }))(CustomTooltip);

  return (
    <ColoredTooltip {...props}>
      {children}
    </ColoredTooltip>
  );
};

function TeammatesTable({ data }) {
  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer style={{ maxHeight: '420px' }}>
      <Table size="small" className='teamsCollapsibleTable'>
        <TableHead>
          <TableRow style={{ textTransform: 'uppercase' }}>
            <TableCell style={{ color: '#98ACC1', fontSize: 10 }}>{t('widgets.widget10.col_colab')}</TableCell>
            <TableCell align='left' style={{ width: '20%', color: '#98ACC1', fontSize: 10 }}>{t('standards.working_day')}</TableCell>
            <TableCell align='left' style={{ width: '20%', color: '#98ACC1', fontSize: 10 }}>{t('standards.performance')}</TableCell>
            <TableCell align='center' style={{ width: '0px', color: '#98ACC1', fontSize: 10, whiteSpace: 'nowrap', textWrap: 'nowrap' }}>{t('widgets.widget10.col_last_month_productive_percent')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((colab, index) => (
            <TableRow
              className={`hover ${parseInt(index) % 2 === 0 ? 'bgColorGray' : '#FFFFFF'} row-main`}
            >
              <TableCell>
                <div className='boxAvatarUsername'>
                  <Avatar
                    alt={colab.username}
                    src={getAvatarUrl({ name: colab.username })}
                    style={{ width: 20, height: 20 }}
                  />
                  {colab.username}
                </div>
              </TableCell>
              <TableCell style={{ width: '20%' }}>


                <div className='boxProductiveTimeCell'>
                  <div className={`total-productive`}>
                    {colab.work_percent + '%'}
                  </div>
                  <CustomColoredTooltip
                    key={index}
                    title={
                      <div style={{padding:'6px'}}>
                        <span style={{ fontSize: '14px', fontWeight: '500' }}>{secondsToHm(colab.totalWorkedTime, false)}</span>
                        <span>-</span>
                        <span style={{ fontSize: '14px', fontWeight: '400' }}>&nbsp;{colab.reach_work_target ? t('widgets.widget10.goal_reached') : t('widgets.widget10.goal_not_reached')}</span>
                      </div>
                    }
                    backgroundColor={colab.reach_work_target ? '#22BEAD' : '#FF7A90'}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    placement="bottom"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -5],
                          },
                        },
                      ],
                    }}
                    interactive>
                    <div className={`progress-column-container `}>
                      <div className={`bar-progress ${colab.reach_work_target ? 'completed' : 'late'}`} style={{ width: `${colab.work_percent}%` }}></div>
                    </div>
                  </CustomColoredTooltip>
                </div>
              </TableCell>
              <TableCell style={{ width: '20%' }}>

                <div className='boxProductiveTimeCell'>
                  <div className={`total-productive`}>
                    {colab.productive_percent + '%'}
                  </div>
                  <CustomColoredTooltip
                    key={index}
                    title={
                      <div style={{padding:'6px'}}>
                        <span style={{ fontSize: '14px', fontWeight: '500' }}>{secondsToHm(colab.totalProductiveTime, false)}</span>
                        <span>-</span>
                        <span style={{ fontSize: '14px', fontWeight: '400' }}>&nbsp;{colab.reach_productive_target ? t('widgets.widget10.goal_reached') : t('widgets.widget10.goal_not_reached')}</span>
                      </div>
                    }
                    backgroundColor={colab.reach_productive_target ? '#22BEAD' : '#FF7A90'}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    placement="bottom"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -5],
                          },
                        },
                      ],
                    }}
                    interactive>
                    <div className={`progress-column-container `}>
                      <div className={`bar-progress ${colab.reach_productive_target ? 'completed' : 'late'}`} style={{ width: `${colab.productive_percent}%` }}></div>
                    </div>
                  </CustomColoredTooltip>
                </div>
              </TableCell>
              <TableCell style={{ width: '0px', textAlign: 'center', whiteSpace: 'nowrap', textWrap: 'nowrap' }}>{`${colab?.last_month_productive_percent || 0}%`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TeammatesTable;
