import React, { useRef, useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from "redux";

import { useTable, useSortBy, useExpanded } from "react-table";

import { activitiesActions } from "../../../_actions";
import { statsActions } from "../../../_actions";

import { secondsToHm, TruncateNumberWithoutRounding } from "../../../_helpers";

import IconTeam from "../../_icons/IconTeam";
import IconTeammate from "../../_icons/IconTeammate";
import IconInfo from "../../_icons/IconInfo";
import IconCompleted from "../../_icons/IconCompleted";
import IconOnline from "../../_icons/IconOnline";
import IconOffline from "../../_icons/IconOffline";
import IconHappy from "../../_icons/IconHappy";
import IconExpand from "../../_icons/IconExpand";
import IconReduce from "../../_icons/IconReduce";
import { isProductive } from "../../../_helpers/calculate";

function TeamsFull(props) {
  const { t, i18n } = useTranslation("common");
  const { userDetails } = props;
  let goals = props.goals || [];
  let stats = props.stats || [];
  let filter = props.filter || {};

  const [filterProductive, setFilterProductive] = useState(true);
  const [filterUnproductive, setFilterUnproductive] = useState(true);
  const [TotalProductiveTeams, setProductiveTeams] = useState(0);
  const [TotalUnproductiveTeams, setUnproductiveTeams] = useState(0);

  const [TotalWorkedTime, setTotalWorkedTime] = useState(0);
  const [TotalExpectedTime, setTotalExpectedTime] = useState(0);
  const [TotalExpectedProductive, setTotalExpectedProductive] = useState(0);
  const [TotalProductiveTime, setTotalProductiveTime] = useState(0);
  const [PercentWorkedTime, setPercentWorkedTime] = useState(0);
  const [PercentProductiveTime, setPercentProductiveTime] = useState(0);
  const [TotalOnlineTime, setTotalOnlineTime] = useState(0);
  const [TotalOfflineTime, setTotalOfflineTime] = useState(0);
  const [PercentOnlineTime, setPercentOnlineTime] = useState(0);
  const [PercentOfflineTime, setPercentOfflineTime] = useState(0);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  let PercentHappy = 0;
  let HappyMoodUsers = stats.HappyMoodUsers || 0;
  let OkMoodUsers = stats.OkMoodUsers || 0;
  let SadMoodUsers = stats.SadMoodUsers || 0;

  let TotalMoodUsers = 0;
  TotalMoodUsers = HappyMoodUsers + OkMoodUsers + SadMoodUsers;

  if (TotalMoodUsers > 0) {
    PercentHappy = Math.round((HappyMoodUsers / TotalMoodUsers) * 100);
  }

  const createData = () => {
    setData(goals);
  };

  const calculatePercent = (a, b) => {
    if (a > 0 && b > 0) {
      return (a / b) * 100;
    }
    return 0;
  };

  const createColumns = () => {
    setColumns([
      {
        Header: t("teams.teams"),
        accessor: "name",
        show: true,
        Cell: (d) => {
          return <div className='cellShrinkTable'>
                      <span title={d.value}>{d.value}</span>
                  </div>
        }
      },
      {
        Header: t("teams.teammates"),
        accessor: "totalTeammates",
        Cell: (d) => d.row.original.totalTeammates,
        show: true,
      },

      {
        Header: `${t("standards.working_day")} (h | %)`,
        accessor: (d) => d.totalWorkedTime,
        show: userDetails.allowed_total_time,
        Cell: (d) => {
          const row = d.row.original;
          const percentWorked = calculatePercent(
            row.totalWorkedTime,
            row.totalExpectedWorkedTime
          );
          return <div className="time">
                  {`${secondsToHm(row.totalWorkedTime)} | ${TruncateNumberWithoutRounding(percentWorked)}%`}
                </div>;
        },
      },
      {
        Header: `${t("standards.performance")} (h | %)`,
        accessor: (d) => d.totalProductiveTime,
        show: userDetails.allowed_productive.includes("related"),
        Cell: (d) => {
          const percentWorked = calculatePercent(
            d.row.original.totalProductiveTime,
            d.row.original.totalExpectedProductiveTime
          );
          const type = percentWorked < 100 ? "unproductive" : "productive";
          return (
            <div className={"work " + type}>
              {`${secondsToHm(d.row.original.totalProductiveTime)} | ${TruncateNumberWithoutRounding(percentWorked)}%`}
            </div>
          );
        },
      },
      {
        Header: `${t("teams.on")} | ${t("teams.off")}`,
        id: "totalWorkedTime",
        accessor: (d) =>
          calculatePercent(
            d.totalProductiveTime - d.totalProductiveTimeOutdoor,
            d.totalExpectedProductiveTime
          ),
        show: true,
        Cell: (d) => {
          const percentOnline = calculatePercent(
            d.row.original.totalProductiveTime -
              d.row.original.totalProductiveTimeOutdoor,
            d.row.original.totalExpectedProductiveTime
          );
          const percentWorkedOutdoor = calculatePercent(
            d.row.original.totalProductiveTimeOutdoor,
            d.row.original.totalExpectedProductiveTime
          );
          return <div className="work">
                  {`${percentOnline.toFixed(2)}% | ${percentWorkedOutdoor.toFixed(2)}%`}
                  </div>;
        },
      },
      {
        Header: t("teams.happiness"),
        id: "happyMoodUsers",
        show: true,
        Cell: (d) => d.row.original.happiness_percent + "%",
      },
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        show: true,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span
            className={isAllRowsExpanded ? "expand expanded" : "expand"}
            {...getToggleAllRowsExpandedProps()}
          >
            {isAllRowsExpanded ? <IconReduce /> : <IconExpand />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand > 0 ? (
            <span className="expand" {...row.getToggleRowExpandedProps({})}>
              {row.isExpanded ? <IconReduce /> : <IconExpand />}
            </span>
          ) : null,
      },
    ]);
  };

  useEffect(() => {
    let totalProductiveTeams = 0;
    let totalTeams = 0;
    if (goals) {
      for (let t0 of goals) {
        totalTeams++;
        if (isProductive(t0)) {
          totalProductiveTeams++;
        }
        t0.totalTeammates = t0.machineUsers.length || 0;
        if (t0.children.length > 0) {
          for (let t1 of t0.children) {
            t0.totalTeammates += t1.machineUsers.length;
            t1.totalTeammates = t1.machineUsers.length;
            totalTeams++;
            if (isProductive(t1)) {
              totalProductiveTeams++;
            }
            if (t1.children.length > 0) {
              for (let t2 of t1.children) {
                t0.totalTeammates += t2.machineUsers.length;
                t1.totalTeammates += t2.machineUsers.length;
                t2.totalTeammates = t2.machineUsers.length;
                totalTeams++;
                if (isProductive(t2)) {
                  totalProductiveTeams++;
                }
                if (t2.children.length > 0) {
                  totalTeams++;
                  for (let t3 of t2.children) {
                    t0.totalTeammates += t3.machineUsers.length;
                    t1.totalTeammates += t3.machineUsers.length;
                    t2.totalTeammates += t3.machineUsers.length;
                    t3.totalTeammates = t3.machineUsers.length;
                    if (isProductive(t3)) {
                      totalProductiveTeams++;
                    }
                    if (t3.children.length > 0) {
                      totalTeams++;
                      for (let t4 of t3.children) {
                        t0.totalTeammates += t4.machineUsers.length;
                        t1.totalTeammates += t4.machineUsers.length;
                        t2.totalTeammates += t4.machineUsers.length;
                        t3.totalTeammates += t4.machineUsers.length;
                        t4.totalTeammates = t4.machineUsers.length;
                        if (isProductive(t4)) {
                          totalProductiveTeams++;
                        }
                        if (t4.children.length > 0) {
                          for (let t5 of t4.children) {
                            t0.totalTeammates += t5.machineUsers.length;
                            t1.totalTeammates += t5.machineUsers.length;
                            t2.totalTeammates += t5.machineUsers.length;
                            t3.totalTeammates += t5.machineUsers.length;
                            t4.totalTeammates += t5.machineUsers.length;
                            t5.totalTeammates = t5.machineUsers.length;
                            totalTeams++;
                            if (isProductive(t5)) {
                              totalProductiveTeams++;
                            }
                            if (t5.children.length > 0) {
                              for (let t6 of t5.children) {
                                t0.totalTeammates += t6.machineUsers.length;
                                t1.totalTeammates += t6.machineUsers.length;
                                t2.totalTeammates += t6.machineUsers.length;
                                t3.totalTeammates += t6.machineUsers.length;
                                t4.totalTeammates += t6.machineUsers.length;
                                t5.totalTeammates += t6.machineUsers.length;
                                t6.totalTeammates = t6.machineUsers.length;
                                totalTeams++;
                                if (isProductive(t6)) {
                                  totalProductiveTeams++;
                                }
                                if (t6.children.length > 0) {
                                  for (let t7 of t6.children) {
                                    t0.totalTeammates += t7.machineUsers.length;
                                    t1.totalTeammates += t7.machineUsers.length;
                                    t2.totalTeammates += t7.machineUsers.length;
                                    t3.totalTeammates += t7.machineUsers.length;
                                    t4.totalTeammates += t7.machineUsers.length;
                                    t5.totalTeammates += t7.machineUsers.length;
                                    t6.totalTeammates += t7.machineUsers.length;
                                    t7.totalTeammates = t7.machineUsers.length;
                                    totalTeams++;
                                    if (isProductive(t7)) {
                                      totalProductiveTeams++;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // setProductiveTeams(totalProductiveTeams)
    // setUnproductiveTeams(totalTeams - totalProductiveTeams)
    createData();
    createColumns();
  }, [goals]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
      getSubRows: (row) => row.children,
      disableMultiSort: true,
      initialState: {
        sortBy: [
          {
            id: "totalExpectedProductiveTime",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    if (filterProductive) {
      var elems = document.querySelectorAll(".productive");

      [].forEach.call(elems, function (el) {
        el.parentNode.parentNode.classList.remove("hide");
      });
    } else {
      var elems = document.querySelectorAll(".productive");

      [].forEach.call(elems, function (el) {
        el.parentNode.parentNode.classList.add("hide");
      });
    }

    if (filterUnproductive) {
      var elems = document.querySelectorAll(".unproductive");

      [].forEach.call(elems, function (el) {
        el.parentNode.parentNode.classList.remove("hide");
      });
    } else {
      var elems = document.querySelectorAll(".unproductive");

      [].forEach.call(elems, function (el) {
        el.parentNode.parentNode.classList.add("hide");
      });
    }
  }, [filterProductive, filterUnproductive, expanded]);

  useEffect(() => {
    if (stats) {
      let totalExpectedWorkedTime = stats.TotalExpectedWorkedTime;
      let totalWorkedTime = stats.TotalWorkedTime;
      let totalWorkedTimeOutdoor = stats.TotalWorkedTimeOutdoor;

      let totalProductiveTime = stats.TotalProductiveTime;
      let totalProductiveTimeOutdoor = stats.TotalProductiveTimeOutdoor;
      let totalExpectedProductiveTime = stats.TotalExpectedProductiveTime;

      let percentWorkedTime = calculatePercent(
        totalWorkedTime,
        totalExpectedWorkedTime
      );
      //let percentWorkedTimeOutdoor = calculatePercent(totalWorkedTimeOutdoor, totalExpectedWorkedTime);
      let percentProductiveTime = calculatePercent(
        totalProductiveTime,
        totalExpectedProductiveTime
      );
      let percentProductiveOutdoor = calculatePercent(
        totalProductiveTimeOutdoor,
        totalExpectedProductiveTime
      );

      let timeOnline = totalWorkedTime - totalWorkedTimeOutdoor;
      let timeOffline = totalWorkedTimeOutdoor;

      setTotalWorkedTime(totalWorkedTime);
      setTotalExpectedTime(totalExpectedWorkedTime);

      setTotalProductiveTime(totalProductiveTime);

      setPercentWorkedTime(percentWorkedTime);
      setPercentProductiveTime(percentProductiveTime);

      setTotalOnlineTime(timeOnline);
      setTotalOfflineTime(timeOffline);

      setPercentOnlineTime(percentProductiveTime - percentProductiveOutdoor);
      setPercentOfflineTime(percentProductiveOutdoor);

      setProductiveTeams(stats.TotalProductiveTeams);
      setUnproductiveTeams(stats.TotalTeams - stats.TotalProductiveTeams);
    }
  }, [stats]);

  return (
    <div className="box teams noBg">
      <div className="pageHeader compact">
        <h3>
          {t("teams.teams")}
          <span>
            <IconInfo />
            <div className="tooltip">
              {/*<IconInfo/>*/}
              {t("teams.tooltip")}
            </div>
          </span>
        </h3>
        <div className="internalFilters">
          <div
            className={
              filterProductive
                ? "filter canFilter work active"
                : "filter work canFilter"
            }
            onClick={() => {
              setFilterProductive(!filterProductive);
            }}
          >
            <div className={"head"}>
              <div className="icon">
                <IconTeam />
              </div>
            </div>
            <div className={"highlight"}>{TotalProductiveTeams}</div>
            <div className="name">{t("teams.work_goal_reached")}</div>
          </div>
          <div
            className={
              filterUnproductive
                ? "filter canFilter work active"
                : "filter work  canFilter"
            }
            onClick={() => {
              setFilterUnproductive(!filterUnproductive);
            }}
          >
            <div className={"head"}>
              <div className="icon">
                <IconTeam />
              </div>
            </div>
            <div className={"highlight"}>{TotalUnproductiveTeams}</div>
            <div className="name">{t("teams.work_goal_not_reached")}</div>
          </div>

          {userDetails.allowed_total_time == true && (
            <div className="filter active time">
              <div className={"head"}>
                <div className="icon">
                  <IconCompleted />
                </div>
                <div className={"number"}>
                  {secondsToHm(stats.TotalWorkedTimeAVG)}
                </div>
              </div>
              <div className="highlight">{PercentWorkedTime.toFixed(2)}%</div>
              <div className="name">{t("standards.working_day")}</div>
            </div>
          )}
          {userDetails.allowed_productive.includes("related") && (
            <div className="filter active work">
              <div className={"head"}>
                <div className="icon">
                  <IconCompleted />
                </div>
                <div className="number">
                  {secondsToHm(stats.TotalProductiveTimeAVG)}
                </div>
              </div>
              <div className="highlight">
                {PercentProductiveTime.toFixed(2)}%
              </div>
              <div className="name">{t("standards.performance")}</div>
            </div>
          )}
          <div className="filter active work">
            <div className={"head"}>
              <div className="icon">
                <IconOnline />
              </div>
              <div className="number">
                {secondsToHm(stats.totalOnlineTimeAVG)}
              </div>
            </div>
            <div className="highlight">{PercentOnlineTime.toFixed(2)}%</div>
            <div className="name">{t("teams.online_activities")}</div>
          </div>
          <div className="filter active work">
            <div className={"head"}>
              <div className="icon">
                <IconOffline />
              </div>
              <div className="number">
                {secondsToHm(stats.TotalWorkedTimeOutdoorAVG)}
              </div>
            </div>
            <div className="highlight">{PercentOfflineTime.toFixed(2)}%</div>
            <div className="name">
              {t("teams.offline_activities")} <b></b>
            </div>
          </div>
          <div className="filter active mood">
            <div className={"head"}>
              <div className="icon">
                <IconHappy />
              </div>
            </div>
            <div className="highlight">{stats.happiness_percent}%</div>
            <div className="name">{t("teams.happiness")}</div>
          </div>
        </div>
      </div>
      <div className="dataTable" style={{ overflow: "auto" }}>
        {data.length > 0 && (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.show) {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <div className="arrowDown"></div>
                              ) : (
                                <div className="arrowUp"></div>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      );
                    }
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                let rowClass = "";
                prepareRow(row);
                if (row.isExpanded) {
                  rowClass += " expanded";
                }
                if (row.depth > 0) {
                  rowClass += " sub level" + row.depth;
                }
                if (row.canExpand) {
                  rowClass += " canExpand";
                }

                return (
                  <tr className={rowClass} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.show) {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                      return null;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    activitiesActions: bindActionCreators(activitiesActions, dispatch),
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    goals: state.goals.payload,
    stats: state.stats.payload,
    filter: state.filter,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsFull);
